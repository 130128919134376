import '../style/component/Partners.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const linkStyle = {
  pointerEvents: 'none',
};

const Partners = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className='inner'>
        <h2 className='section_title'>Partners</h2>
        <p className='section_desc'>
          <FormattedMessage id="partners_title_desc" />
        </p>
      </div >

      <div className="content">
        {isMobile ? (
          <>
            <div className="partners_itm">
              <ul>
                <li>
                  <a href="http://ai-dapps.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners01.jpg'} alt='ai-dapps 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners02.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="http://meta-globe.co.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners03.jpg'} alt='meta-globe 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://tiuminp.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners04.jpg'} alt='tiuminp 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://iidm.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners05.jpg'} alt='iidm 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners06.jpg'} alt='' />
                  </a>
                </li>
              </ul>
            </div>
            <div className="partners_itm">
              <ul>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners07.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners08.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.global-connex.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners09.jpg'} alt='global-connex 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="https://www.changbal.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners10.jpg'} alt='changbal 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners11.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.washingtontechnology.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners12.jpg'} alt='wtia 홈페이지' />
                  </a>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="partners_itm partners_itm_top">
              <ul>
                <li>
                  <a href="http://ai-dapps.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners01.jpg'} alt='ai-dapps 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners02.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="http://meta-globe.co.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners03.jpg'} alt='meta-globe 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://tiuminp.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners04.jpg'} alt='tiuminp 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://iidm.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners05.jpg'} alt='iidm 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners06.jpg'} alt='' />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="http://ai-dapps.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners01.jpg'} alt='ai-dapps 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners02.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="http://meta-globe.co.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners03.jpg'} alt='meta-globe 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://tiuminp.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners04.jpg'} alt='tiuminp 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="http://iidm.kr/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners05.jpg'} alt='iidm 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners06.jpg'} alt='' />
                  </a>
                </li>
              </ul>
            </div>
            <div className="partners_itm partners_itm_bottom">
              <ul>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners07.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners08.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.global-connex.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners09.jpg'} alt='global-connex 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="https://www.changbal.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners10.jpg'} alt='changbal 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners11.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.washingtontechnology.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners12.jpg'} alt='wtia 홈페이지' />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners07.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners08.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.global-connex.com/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners09.jpg'} alt='global-connex 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="https://www.changbal.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners10.jpg'} alt='changbal 홈페이지' />
                  </a>
                </li>
                <li>
                  <a href="#" style={linkStyle}>
                    <img src={process.env.PUBLIC_URL + '/assets/partners11.jpg'} alt='' />
                  </a>
                </li>
                <li>
                  <a href="https://www.washingtontechnology.org/" target="_blank">
                    <img src={process.env.PUBLIC_URL + '/assets/partners12.jpg'} alt='wtia 홈페이지' />
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}

      </div>
    </>
  )
}

export default Partners