import React, { useRef } from 'react';

import '../style/component/ContactUs.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// input placeholder에 다국어 넣기위해 만듬
import { useIntl } from 'react-intl';

// emailjs 가져오기
// https://skm1104.tistory.com/61 참고
// https://7357.tistory.com/54 참고
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  // --- { emailjs 기본제공 코드
  const form = useRef();
  const intl = useIntl();

  const sendEmail = (e) => {
    e.preventDefault();

    // --- { 글자수 경고창
    const from_name = e.target.from_name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const message = e.target.message.value;

    if (from_name.length < 2 || email.length < 2 || phone.length < 2 || message.length < 2) {
      alert('모든 정보에 2글자 이상 입력하세요.');
      return;
    }
    // --- }

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs.sendForm('service_c2hi2br', 'template_eon0h3i', form.current, 'zhpfiT2SZxFOFemsT')
      .then(
        (result) => {
          alert("문의가 접수되었습니다.");
        },
        (error) => {
          alert("전송을 실패했습니다.");
        }
      );
  };
  // --- }

  // --- { input placeholder에 다국어 넣기위해 만듬
  const from_name = intl.formatMessage({ id: 'contact_itm_from_name' });
  const email = intl.formatMessage({ id: 'contact_itm_email' });
  const phone = intl.formatMessage({ id: 'contact_itm_phone' });
  const message = intl.formatMessage({ id: 'contact_itm_message' });
  const send = intl.formatMessage({ id: 'contact_itm_send' });
  // --- }

  return (
    <div className='inner'>
      <h2 className='section_title'>Contact Us</h2>
      <p className='section_desc'>
        <FormattedMessage id="contactus_title_desc" />
      </p>

      <div className="content">
        <div className="contact_itm">
          <form ref={form} onSubmit={sendEmail}>
            <input
              className='from_name'
              type="text"
              name="from_name"
              placeholder={from_name}
            />
            <input
              className='email'
              type="email"
              name="email"
              placeholder={email}
            />
            <input
              className='phone'
              type='tel'
              name="phone"
              placeholder={phone}
            />
            <textarea
              className='message'
              name="message"
              placeholder={message}
            />
            <input
              className='send'
              type="submit"
              value={send}
            />
          </form>
        </div>

        <div className="contact_info">
          <div className='contact_info_text'>
            <div>
              <strong>Opening hours</strong>
              <span>Monday - Friday</span>
              <span>10am - 5pm</span>
              <span>Weekend</span>
              <span>Closed</span>
            </div>
            <div>
              <strong>Address</strong>
              <span>#5152, 5th floor, 6, Bongeunsa-ro 1-gil,</span>
              <span>Gangnam-gu, Seoul, Republic of Korea</span>
            </div>
            <div>
              <strong>Support</strong>
              <span>hello@petcarelab.net</span>
              {/* <span>+01 23 4567-8910</span> */}
            </div>
          </div>
          <figure className="contact_info_img">
            <img src={process.env.PUBLIC_URL + '/assets/contact01.png'} alt='푸들 강아지' />
          </figure>
        </div>
      </div>
    </div>
  )
}

export default ContactUs