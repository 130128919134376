import '../style/component/MainVisual.scss';

// slick-slide
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const sliderSettings = {
  arrows: false,
  dots: false,
  slidesToShow: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 1900,
};

const MainVisual = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className='inner'>
      <div className="main_visual_slogan">
        <div className='main_visual_title_01'>
          <strong className='main_visual_title_bold_01'>
            <FormattedMessage id="main_visual_title_bold_01" />
          </strong>
          <span className='main_visual_title_thin_01'>
            <FormattedMessage id="main_visual_title_thin_01" />
          </span>
        </div>
        <div className='main_visual_title_02'>
          <strong className='main_visual_title_bold_02'>
            <FormattedMessage id="main_visual_title_bold_02" />
          </strong>
          <span className='main_visual_title_thin_02'>
            <FormattedMessage id="main_visual_title_thin_02" />
          </span>
        </div>
        {isMobile ? (
          <p className='main_visual_desc'>
            <FormattedMessage id="main_visual_desc_01" />
            <FormattedMessage id="main_visual_desc_02" />
          </p>
        ) : (
          <p className='main_visual_desc'>
            <FormattedMessage id="main_visual_desc_01" /><br/>
            <FormattedMessage id="main_visual_desc_02" />
          </p>
        )}
      </div>

      <div className="main_visual_images">
        <Slider {...sliderSettings}>
          <img src={process.env.PUBLIC_URL + '/assets/main01.png'} alt='배변판 위에 올라간 강아지' />
          <img src={process.env.PUBLIC_URL + '/assets/main02.png'} alt='배변판 위에 올라간 강아지' />
          <img src={process.env.PUBLIC_URL + '/assets/main03.png'} alt='배변판 위에 올라간 강아지' />
        </Slider>
      </div>

      <div className="scroll">
        <div className="line">
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default MainVisual
