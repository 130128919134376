import { useEffect, useRef, useState } from "react";
import '../style/component/Product.scss';
// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// slick-slide
import Slide from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//react-icons
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const Product = () => {
  const isMobile = useMediaQuery({ maxWidth: 1138 });
  const s = useRef(null);

  const settings = {
    customPaging: function (i) {
      const imageName = `product_btn0${i + 1}.jpg`;
      return (
        <div>
          <img src={`${process.env.PUBLIC_URL}/assets/${imageName}`} />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: idx => setSNum(idx),
  }

  const [sNum, setSNum] = useState();
  useEffect(() => {
    setSNum(0);
  }, [])

  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);

  const handlePrevClick = () => {
    s.current.slickPrev();
    setIsPrevActive(true);
    setIsNextActive(false);
  };

  const handleNextClick = () => {
    s.current.slickNext();
    setIsPrevActive(false);
    setIsNextActive(true);
  };

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    afterChange: idx => setSNum(idx),
    responsive: [
      {
        breakpoint: 1138,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <>
      <div className='inner'>
        <h2 className='section_title'>Product</h2>
        <p className='section_desc'>
          <FormattedMessage id="product_title_desc" />
        </p>
        <div className="content">
          <Slide {...settings} ref={s}>
            {Array.from({ length: 9 }, (_, i) => (
              <div key={i}>
                <img src={`${process.env.PUBLIC_URL}/assets/product${String(i + 1).padStart(2, '0')}.jpg`} alt='스마트 배변판' />
              </div>
            ))}
          </Slide>
          {/* note
            위의 코드에서는 Array.from()을 사용하여 길이가 9인 배열을 생성하고, map()을 사용하여 배열을 순회합니다. 
            String(i + 1).padStart(2, '0')를 사용하여 숫자를 2자리로 만들고 앞에 0을 채워넣어 이미지 파일 이름을 동적으로 생성합니다. 
            이렇게 하면 product01.jpg부터 product09.jpg까지의 이미지 파일을 순서대로 가져올 수 있습니다. 
          */}

          <div className="arrows">
            <button
              onClick={handlePrevClick}
              className={`prev btn ${isPrevActive ? 'active' : ''}`}>
              <CgChevronLeft />
            </button>
            <button
              onClick={handleNextClick}
              className={`next btn ${isNextActive ? 'active' : ''}`}>
              <CgChevronRight />
            </button>
          </div>

        </div>
      </div>

      <div className="product_itm_wrap">
        <div className="product_itm">
          {isMobile ? (
            <Slide {...sliderSettings}>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_01" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm01_m.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_01_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_01_02" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_02" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm02_m.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_02_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_02_02" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_03" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm03_m.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_02" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_03" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="1400" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_04" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm04_m.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_04_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_04_02" />
                </span>
                
              </div>
            </Slide>
          ) : (
            <>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_01" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm01.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_01_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_01_02" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_02" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm02.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_02_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_02_02" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_03" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm03.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_02" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_03_03" />
                </span>
              </div>
              <div className="product_itm_desc" data-aos="fade-up" data-aos-delay="1400" data-aos-duration="1000">
                <div>
                  <strong>
                    <FormattedMessage id="product_itm_title_desc_04" />
                  </strong>
                  <img src={process.env.PUBLIC_URL + '/assets/product_itm04.png'} alt='' />
                </div>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_04_01" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_04_02" />
                </span>
                <span>
                  <img src={process.env.PUBLIC_URL + '/assets/check.png'} alt='' />
                  <FormattedMessage id="product_itm_desc_04_03" />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

}

export default Product