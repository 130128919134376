// warning 안뜨게 하기
/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import { Nav } from './data/Data';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import { IntlProvider } from "react-intl";
import enUsMsg from "./lang/en-US.json";
import koMsg from "./lang/ko.json";
import QuickMenu from './components/QuickMenu';

const App = () => {
    const defaultLocale = "ko"; // 기본 언어 설정
    const [locale, setLocale] = useState(defaultLocale);
    const messages = { ko: koMsg, "en-US": enUsMsg }[locale];


    const componentRefs = {
        About: useRef(null),
        Service: useRef(null),
        Product: useRef(null),
        Partners: useRef(null),
        News: useRef(null),
        Notice: useRef(null),
        ContactUs: useRef(null),
    };


    const [activeNavItem, setActiveNavItem] = useState('About');

    const scrollToComponent = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleNavItemClick = (componentName) => {
        scrollToComponent(componentRefs[componentName]);
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;

        // 각 컴포넌트의 위치를 기준으로 activeNavItem 업데이트
        if (scrollPosition < 80) {
            setActiveNavItem('fake');
        } else if (
            scrollPosition >= componentRefs.About.current.offsetTop - 80 &&
            scrollPosition < componentRefs.Service.current.offsetTop - 80
        ) {
            setActiveNavItem('About');
        } else if (
            scrollPosition >= componentRefs.Service.current.offsetTop - 80 &&
            scrollPosition < componentRefs.Product.current.offsetTop - 80
        ) {
            setActiveNavItem('Service');
        } else if (
            scrollPosition >= componentRefs.Product.current.offsetTop - 80 &&
            scrollPosition < componentRefs.Partners.current.offsetTop - 80
        ) {
            setActiveNavItem('Product');
        } else if (
            scrollPosition >= componentRefs.Partners.current.offsetTop - 80 &&
            scrollPosition < componentRefs.News.current.offsetTop - 80
        ) {
            setActiveNavItem('Partners');
        } else if (
            scrollPosition >= componentRefs.News.current.offsetTop - 80 &&
            scrollPosition < componentRefs.Notice.current.offsetTop - 80
        ) {
            setActiveNavItem('News');
        } else if (
            scrollPosition >= componentRefs.Notice.current.offsetTop - 80 &&
            scrollPosition < componentRefs.ContactUs.current.offsetTop - 80
        ) {
            setActiveNavItem('Notice');
        } else if (
            scrollPosition >= componentRefs.ContactUs.current.offsetTop - 80
        ) {
            setActiveNavItem('ContactUs');
        }
    };

    useEffect(() => {
        // 스크롤 이벤트가 발생하기 전에 active 상태 초기화
        setActiveNavItem('fake');

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트되면 스크롤 이벤트 리스너 해제
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleLocaleChange = (newLocale) => {
        setLocale(newLocale);
        localStorage.setItem("locale", newLocale);
    };

    useEffect(() => {
        if (!localStorage.getItem("locale")) {
            localStorage.setItem("locale", defaultLocale);
        }
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <Header
                Nav={Nav}
                handleNavItemClick={handleNavItemClick}
                activeNavItem={activeNavItem}
                handleLocaleChange={handleLocaleChange}
            />
            <Main
                componentRefs={componentRefs}
            />
            <Footer />
            <QuickMenu
                handleNavItemClick={handleNavItemClick}
            />
        </IntlProvider>
    )
}

export default App