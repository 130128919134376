import '../style/component/Footer.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

import { LuShieldCheck } from "react-icons/lu";
import { Link } from 'react-router-dom';
import Privacy from '../pages/Privacy';
import { useState } from 'react';

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showPrivacy, setShowPrivacy] = useState(false); // Privacy 컴포넌트의 가시성을 제어하기 위한 상태 변수

  const handlePrivacyClick = () => {
    setShowPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setShowPrivacy(false);
  };

  return (
    <footer id="footer">
      <div className="inner">
        {isMobile ? (
          <>
            <div className='footer_info_wrap'>
              <div className='footer_social'>
                {/* sns 주소 생기면 링크 걸기 */}
                <ul className='footer_sns'>
                  <a href="https://t.me/petcarelab" target="_blank" alt='텔레그램'><li></li></a>
                  <a href="https://twitter.com/PetCare_Lab" target="_blank" alt='트위터'><li></li></a>
                  <a href="https://blog.naver.com/petcarelab" target="_blank" alt='블로그'><li></li></a>
                  <a href="https://www.youtube.com/@PetcareLab_official" target="_blank" alt='유투브'><li></li></a>
                  <a href="https://github.com/PCL-project" target="_blank" alt='깃허브'><li></li></a>
                </ul>
              </div>
              <div className='footer_privacy_wrapper'>
                <img className="footer_logo" src={process.env.PUBLIC_URL + '/assets/footer_logo.png'} alt='logo' />
                <Link to="#" onClick={handlePrivacyClick} className='footer_privacy'>
                  <LuShieldCheck />
                  <FormattedMessage id="footer_privacy" />
                </Link>
              </div>
              <div className='footer_info'>
                <div className='footer_info_top'>
                  <span><FormattedMessage id="footer_info_01" /></span>
                  <span><FormattedMessage id="footer_info_02" /></span>
                  <span><FormattedMessage id="footer_info_03" /></span>
                  <span><FormattedMessage id="footer_info_04" /></span>
                  <span><FormattedMessage id="footer_info_05" /></span>
                </div>
                <span className='copyright'>&copy;Copyright Petcarelab., Ltd. All rights reserved.</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='footer_info_wrap'>
              {/* 링크를 클릭할 때 클릭 이벤트를 처리하기 위해 링크를 수정합니다. */}
              <Link to="#" onClick={handlePrivacyClick} className='footer_privacy'>
                <LuShieldCheck />
                <FormattedMessage id="footer_privacy" />
              </Link>
              <div className='footer_info'>
                <span className='copyright'>&copy;Copyright Petcarelab all rights reserved.</span>
                <div className='footer_info_top'>
                  <span><FormattedMessage id="footer_info_01" /></span>
                  <span><FormattedMessage id="footer_info_02" /></span>
                  <span><FormattedMessage id="footer_info_03" /></span>
                </div>
                <div className='footer_info_bottom'>
                  <span><FormattedMessage id="footer_info_04" /></span>
                  <span><FormattedMessage id="footer_info_05" /></span>
                </div>
              </div>
            </div>
            <div className='footer_social'>
              {/* sns 주소 생기면 링크 걸기 */}
              <div className='footer_sns'>
                <a href="https://t.me/petcarelab" target="_blank" alt='텔레그램'><li></li></a>
                <a href="https://twitter.com/PetCare_Lab" target="_blank" alt='트위터'><li></li></a>
                <a href="https://blog.naver.com/petcarelab" target="_blank" alt='블로그'><li></li></a>
                <a href="https://www.youtube.com/@PetcareLab_official" target="_blank" alt='유투브'><li></li></a>
                <a href="https://github.com/PCL-project" target="_blank" alt='깃허브'><li></li></a>
              </div>
              <img className="footer_logo" src={process.env.PUBLIC_URL + '/assets/footer_logo.png'} alt='logo' />
            </div>
          </>
        )}
      </div>
      {/* Privacy 컴포넌트를 팝업 창으로 렌더링합니다. */}
      {showPrivacy && <Privacy onClose={handleClosePrivacy} />}

    </footer >
  )
}

export default Footer