import { useEffect, useRef, useState } from "react";
import '../style/component/Notice.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// slick-slide
import Slide from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//react-icons
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";

const Notice = () => {
  const s = useRef(null);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: idx => setSNum(idx),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const [sNum, setSNum] = useState();
  useEffect(() => {
    setSNum(0);
  }, [])

  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);

  const handlePrevClick = () => {
    s.current.slickPrev();
    setIsPrevActive(true);
    setIsNextActive(false);
  };

  const handleNextClick = () => {
    s.current.slickNext();
    setIsPrevActive(false);
    setIsNextActive(true);
  };

  return (
    <div className='inner'>
      <h2 className='section_title'>Notice</h2>
      <p className='section_desc'>
        <FormattedMessage id="notice_title_desc" />
      </p>

      <div className="content">
        <Slide {...settings} ref={s}>
          <a href="https://blog.naver.com/petcarelab/223179145016" target="_blank">
            <div className="notice_slide notice_slide_01">
              <div className="notice_itm_text">
                <strong>
                  <FormattedMessage id="notice_itm_text_01" />
                </strong>
                <span>
                  <FormattedMessage id="notice_itm_text_desc_01" />
                </span>
                <em>
                  <FormattedMessage id="notice_itm_text_date_01" />
                </em>
              </div>
            </div>
          </a>
          <a href="#!" target="_blank">
            <div className="notice_slide notice_slide_01">
              <div className="notice_itm_text">
                <strong>
                  <FormattedMessage id="notice_itm_text_02" />
                </strong>
                <span>
                  <FormattedMessage id="notice_itm_text_desc_02" />
                </span>
                <em>
                  <FormattedMessage id="notice_itm_text_date_02" />
                </em>
              </div>
            </div>
          </a>
          <a href="#!" target="_blank">
            <div className="notice_slide notice_slide_01">
              <div className="notice_itm_text">
                <strong>
                  <FormattedMessage id="notice_itm_text_02" />
                </strong>
                <span>
                  <FormattedMessage id="notice_itm_text_desc_02" />
                </span>
                <em>
                  <FormattedMessage id="notice_itm_text_date_02" />
                </em>
              </div>
            </div>
          </a>
          <a href="#!" target="_blank">
            <div className="notice_slide notice_slide_01">
              <div className="notice_itm_text">
                <strong>
                  <FormattedMessage id="notice_itm_text_02" />
                </strong>
                <span>
                  <FormattedMessage id="notice_itm_text_desc_02" />
                </span>
                <em>
                  <FormattedMessage id="notice_itm_text_date_02" />
                </em>
              </div>
            </div>
          </a>
        </Slide>

        <div className="arrows">
          <button
            onClick={handlePrevClick}
            className={`prev btn ${isPrevActive ? 'active' : ''}`}>
            <CgChevronLeft />
          </button>
          <button
            onClick={handleNextClick}
            className={`next btn ${isNextActive ? 'active' : ''}`}>
            <CgChevronRight />
          </button>
        </div>
      </div>

    </div>
  )
}

export default Notice