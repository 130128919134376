import '../style/component/Service.scss';
import { FaAndroid, FaApple } from "react-icons/fa6";
import { Link } from 'react-router-dom';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

// 경고창을 띄워 준비중임을 알림
const Aalert = () => {
  alert("Comming Soon");
};

const Service = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className='inner'>
      <div className='service_bg_m'></div>
      <h2 className='section_title'>Service</h2>
      <p className='section_desc'>
        <FormattedMessage id="service_title_desc" />
      </p>

      <img className="strip" src={process.env.PUBLIC_URL + '/assets/strap.png'} alt='스트립 이미지' />

      {isMobile ? (
        <div className="content">

          <div className="service_content_text" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
            <div className="service_content_title">
              <span className="service_content_title_01">
                <FormattedMessage id="service_content_title_01" />
              </span>
              <strong className="service_content_title_02">
                <FormattedMessage id="service_content_title_02" />
              </strong>
            </div>

            
            <img className="service_phone" src={process.env.PUBLIC_URL + '/assets/service_phone.png'} alt='스마트 배변판 어플리케이션' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" />

            <div className="service_app">
              <Link to='' onClick={Aalert}>
                <button className="android">
                  <FaAndroid />
                  For Android
                </button>
              </Link>
              <Link to='' onClick={Aalert}>
                <button className="ios">
                  <FaApple />
                  For iOS
                </button>
              </Link>
            </div>

            <ul className="service_content_desc">
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon01.png'} alt="" />
                <p className='service_content_desc_01'>
                  <FormattedMessage id="service_content_desc_01" />
                </p>
              </li>
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon02.png'} alt="" />
                <p>
                  <FormattedMessage id="service_content_desc_02" />
                </p>
              </li>
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon03.png'} alt="" />
                <p>
                  <FormattedMessage id="service_content_desc_03" />
                </p>
              </li>
            </ul>
          </div>

        </div>
      ) : (
        <div className="content">
          <img className="service_phone" src={process.env.PUBLIC_URL + '/assets/service_phone.png'} alt='스마트 배변판 어플리케이션' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" />

          <div className="service_content_text" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
            <div className="service_content_title">
              <span className="service_content_title_01">
                <FormattedMessage id="service_content_title_01" />
              </span>
              <strong className="service_content_title_02">
                <FormattedMessage id="service_content_title_02" />
              </strong>
            </div>

            <div className="service_app">
              <Link to='' onClick={Aalert}>
                <button className="android">
                  <FaAndroid />
                  For Android
                </button>
              </Link>
              <Link to='' onClick={Aalert}>
                <button className="ios">
                  <FaApple />
                  For iOS
                </button>
              </Link>
            </div>

            <ul className="service_content_desc">
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon01.png'} alt="" />
                <p className='service_content_desc_01'>
                  <FormattedMessage id="service_content_desc_01" />
                </p>
              </li>
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon02.png'} alt="" />
                <p>
                  <FormattedMessage id="service_content_desc_02" />
                </p>
              </li>
              <li>
                <img src={process.env.PUBLIC_URL + '/assets/service_desc_icon03.png'} alt="" />
                <p>
                  <FormattedMessage id="service_content_desc_03" />
                </p>
              </li>
            </ul>
          </div>

        </div>
      )}

    </div>
  )
}

export default Service