import { useEffect, useState } from 'react';
import '../style/component/QuickMenu.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const QuickMenu = ({ handleNavItemClick }) => {
    const isMobile = useMediaQuery({ maxWidth: 1138 });
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const currentPosition = window.pageYOffset;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleTopButtonClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="quick_menu">
            {isMobile ? (
                <>
                    <div
                        className={`contact quick_btn ${scrollPosition >= 300 ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('ContactUs')}
                    >
                        <img
                            className="contact_icon"
                            src={process.env.PUBLIC_URL + '/assets/contact_btn_m.png'}
                            alt="문의하기로 가기"
                        />
                    </div>
                    <div
                        className={`top quick_btn ${scrollPosition >= 300 ? 'active' : ''}`}
                        onClick={handleTopButtonClick}>
                        <img
                            className="top_icon"
                            src={process.env.PUBLIC_URL + '/assets/top_btn.png'}
                            alt="맨위로 가기"
                        />
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={`contact quick_btn ${scrollPosition >= 300 ? 'active' : ''}`}
                        onClick={() => handleNavItemClick('ContactUs')}
                    >
                        <img
                            className="contact_icon"
                            src={process.env.PUBLIC_URL + '/assets/contact_btn.png'}
                            alt="문의하기로 가기"
                        />
                        <button><FormattedMessage id="inquiry" /></button>
                    </div>
                    <div
                        className={`top quick_btn ${scrollPosition >= 300 ? 'active' : ''}`}
                        onClick={handleTopButtonClick}>
                        <img
                            className="top_icon"
                            src={process.env.PUBLIC_URL + '/assets/top_btn.png'}
                            alt="맨위로 가기"
                        />
                        <button>TOP</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default QuickMenu