import MainVisual from '../pages/MainVisual';
import About from '../pages/About';
import Service from '../pages/Service';
import Product from '../pages/Product';
import Partners from '../pages/Partners';
import News from '../pages/News';
import Notice from '../pages/Notice';
import ContactUs from '../pages/ContactUs';

const Main = ({ componentRefs }) => {

    return (
        <main>
            <section id='MainVisual'>
                <MainVisual />
            </section>
            <section id='About' ref={componentRefs.About}>
                <About />
            </section>
            <section id='Service' ref={componentRefs.Service}>
                <Service />
            </section>
            <section id='Product' ref={componentRefs.Product}>
                <Product />
            </section>
            <section id='Partners' ref={componentRefs.Partners}>
                <Partners />
            </section>
            <section id='News' ref={componentRefs.News}>
                <News />
            </section>
            <section id='Notice' ref={componentRefs.Notice}>
                <Notice />
            </section>
            <section id='ContactUs' ref={componentRefs.ContactUs}>
                <ContactUs/>
            </section>
        </main>
    )
}

export default Main