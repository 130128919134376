import { useEffect, useRef, useState } from "react";
import '../style/component/News.scss';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// slick-slide
import Slide from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//react-icons
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";

const News = () => {
  const s = useRef(null);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: idx => setSNum(idx),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const [sNum, setSNum] = useState();
  useEffect(() => {
    setSNum(0);
  }, [])

  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);

  const handlePrevClick = () => {
    s.current.slickPrev();
    setIsPrevActive(true);
    setIsNextActive(false);
  };

  const handleNextClick = () => {
    s.current.slickNext();
    setIsPrevActive(false);
    setIsNextActive(true);
  };

  return (
    <div className='inner'>
      <h2 className='section_title'>News</h2>
      <p className='section_desc'>
        <FormattedMessage id="news_title_desc" />
      </p>

      <div className="content">
        <Slide {...settings} ref={s}>
        <a href="https://blog.naver.com/petcarelab/223179145016" target="_blank">
            <div className="news_slide news_slide_04">
              <img src={process.env.PUBLIC_URL + '/assets/news04.jpg'} alt='펫케어랩(PetCare Lab) 공식 웹사이트 오픈!' />
              <div className="news_itm_text">
                <strong>
                  <FormattedMessage id="news_itm_text_04" />
                </strong>
                <span>
                  <FormattedMessage id="news_itm_text_date_04" />
                </span>
              </div>
            </div>
          </a>
          <a href="https://www.dailysecu.com/news/articleView.html?idxno=145755" target="_blank">
            <div className="news_slide news_slide_03">
              <img src={process.env.PUBLIC_URL + '/assets/news03.jpg'} alt='에이아이댑스, Global Connex와 협력 관계 구축' />
              <div className="news_itm_text">
                <strong>
                  <FormattedMessage id="news_itm_text_03" />
                </strong>
                <span>
                  <FormattedMessage id="news_itm_text_date_03" />
                </span>
              </div>
            </div>
          </a>
          <a href="http://www.kdpress.co.kr/news/articleView.html?idxno=119540" target="_blank">
            <div className="news_slide news_slide_02">
              <img src={process.env.PUBLIC_URL + '/assets/news02.jpg'} alt='㈜에이아이댑스, Seattle Basecamp Inc와 미국 시장 진출 및 투자유치 MOA 체결' />
              <div className="news_itm_text">
                <strong>
                  <FormattedMessage id="news_itm_text_02" />
                </strong>
                <span>
                  <FormattedMessage id="news_itm_text_date_02" />
                </span>
              </div>
            </div>
          </a>
          <a href="https://www.dailysecu.com/news/articleView.html?idxno=141103" target="_blank">
            <div className="news_slide news_slide_01">
              <img src={process.env.PUBLIC_URL + '/assets/news01.jpg'} alt='에이아이댑스, 반려동물 헬스케어 위한 스마트 배변판 제품 개발 나서' />
              <div className="news_itm_text">
                <strong>
                  <FormattedMessage id="news_itm_text_01" />
                </strong>
                <span>
                  <FormattedMessage id="news_itm_text_date_01" />
                </span>
              </div>
            </div>
          </a>
        </Slide>

        <div className="arrows">
          <button
            onClick={handlePrevClick}
            className={`prev btn ${isPrevActive ? 'active' : ''}`}>
            <CgChevronLeft />
          </button>
          <button
            onClick={handleNextClick}
            className={`next btn ${isNextActive ? 'active' : ''}`}>
            <CgChevronRight />
          </button>
        </div>
      </div>

    </div>
  )
}

export default News