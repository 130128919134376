import '../style/component/About.scss';

//aos
import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Slide from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

// 반응형 조건 줄 때 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from 'react-responsive';

const About = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    AOS.init();
  }, [])

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    afterChange: idx => setSNum(idx),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const s = useRef(null);
  const [sNum, setSNum] = useState();
  useEffect(() => {
    setSNum(0);
  }, [])


  // 스크롤이 0일때 강아지 머리 안보이게
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <div className='inner'>
      <h2 className='section_title'>About</h2>
      <p className='section_desc'>
        <FormattedMessage id="about_title_desc" />
      </p>

      {isMobile ? (
        <>
          {scrollPosition >= 1 && (
            <>
              <img className="about_dog03" src={process.env.PUBLIC_URL + '/assets/about_dog_m03.png'} alt='강아지 이미지' />
              <img className="about_dog01" src={process.env.PUBLIC_URL + '/assets/about_dog_m01.png'} alt='강아지 이미지' />
            </>
          )}
        </>
      ) : (
        <>
          {scrollPosition >= 1 && (
            <>
              <img className="about_dog03" src={process.env.PUBLIC_URL + '/assets/about_dog03.png'} alt='강아지 이미지' />
              <img className="about_dog01" src={process.env.PUBLIC_URL + '/assets/about_dog01.png'} alt='강아지 이미지' />
              <img className="about_dog02" src={process.env.PUBLIC_URL + '/assets/about_dog02.png'} alt='강아지 이미지' />
            </>
          )}
        </>
      )}

      {isMobile ? (
        <Slide {...sliderSettings}>
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about01.jpg'} alt='간편하고 손쉬운 건강관리' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_01" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_01" />
              </span>
            </div>
          </div>
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about02.jpg'} alt='인공지능을 활용한 건강진단' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_02" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_02" />
              </span>
            </div>
          </div>
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about03.jpg'} alt='블록체인을 활용한 건강진단' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_03" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_03" />
              </span>
            </div>
          </div>
        </Slide>
      ) : (
        <div className="content" data-aos="fade-up" data-aos-duration="1400">
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about01.jpg'} alt='간편하고 손쉬운 건강관리' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_01" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_01" />
              </span>
            </div>
          </div>
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about02.jpg'} alt='인공지능을 활용한 건강진단' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_02" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_02" />
              </span>
            </div>
          </div>
          <div className='about_content'>
            <img src={process.env.PUBLIC_URL + '/assets/about03.jpg'} alt='블록체인을 활용한 건강진단' />
            <div className="about_content_text">
              <strong>
                <FormattedMessage id="about_content_title_desc_03" />
              </strong>
              <span>
                <FormattedMessage id="about_content_desc_03" />
              </span>
            </div>
          </div>
        </div>
      )}
    </div >
  )
}

export default About